/**
 * Make sure that the given class is (not) contained in the element's classList. Will add/remove the class if needed.
 *
 * @param {HTMLElement} element the element whose classes should be checked
 * @param {string} className css class to attach/remove
 * @param {boolean} shouldBeActive whether the class should be existent or not on the element
 */
export function synchronizeCssClass({ classList }: HTMLElement, className: string, shouldBeActive: boolean): void {
    const isActive = classList.contains(className);
    if (shouldBeActive && !isActive) {
        classList.add(className);
    } else if (!shouldBeActive && isActive) {
        classList.remove(className);
    }
}

const STRIP_PX_REGEX = /px$/;

/**
 * Strip "px" suffix from a string and convert it to number
 *
 * Useful for converting values returned by "theme.spacing" to numbers.
 *
 * @export
 * @param {string} value
 * @returns Value as number, may be NaN if bad input is provided
 */
export function stripPxSuffix(value: string) {
    return +value.replace(STRIP_PX_REGEX, "");
}
